import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import CheckmarkIcon from '~/icons/iconography/checkmark.svg';
import ErrorIcon from '~/icons/iconography/error.svg';
import { Placeholder } from '~/shared/styles/Form';

export const Column = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const Error = styled(motion.div)(({ theme }) => ({
    color: theme.colors.alert,
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.chicken,
    margin: `${theme.space[1]} 0 0 0`,
    overflow: 'hidden',
    gridArea: 'error',
}));

export const IconError = styled(ErrorIcon)(({ theme }) => ({
    color: theme.colors.alert,
    height: theme.space[3],
    transition: 'opacity 180ms ease',
    width: theme.space[3],
    position: 'absolute',
    top: '50%',
    right: theme.space[2],
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
}));

export const IconSuccess = styled(CheckmarkIcon)(({ theme }) => ({
    color: theme.colors.success,
    height: theme.space[3],
    transition: 'opacity 180ms ease',
    width: theme.space[3],
    position: 'absolute',
    top: '50%',
    right: theme.space[2],
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
}));

export const Input = styled.input<{ hideIcon?: boolean; disabled: boolean | undefined }>(
    ({ theme, hideIcon }) => ({
        border: 'none',
        color: theme.colors.thunderBlack,
        fontSize: theme.fontSizes.mouse,
        width: '100%',
        height: theme.space[6],
        lineHeight: theme.lineHeights.chicken,
        padding: `0 ${hideIcon ? theme.space[2] : '35px'} 0 ${theme.space[2]}`,
        background: 'transparent',

        '&:focus': {
            outline: 'none',
        },

        '&:disabled': {
            color: theme.colors.grey60,
            cursor: 'default',
        },
        // Number inputs
        '&[type="number"]::-webkit-outer-spin-button, &[type="number"]::-webkit-inner-spin-button':
            {
                WebkitAppearance: 'none',
                margin: 0,
            },
        '&[type="number"]': {
            MozAppearance: 'textfield',
        },
    }),
);

export const StyledTextArea = styled.textarea(({ theme }) => ({
    border: 'none',
    color: theme.colors.thunderBlack,
    fontSize: theme.fontSizes.mouse,
    width: '100%',
    lineHeight: theme.lineHeights.chicken,
    background: 'transparent',
    padding: theme.space[2],
    resize: 'none',

    '&:focus': {
        outline: 'none',
    },
}));

export const TextAreaWrapper = styled.div<{
    isHighlighted?: boolean;
}>(
    ({ theme }) => ({
        alignItems: 'center',
        backgroundColor: theme.colors.offWhite,
        border: `1px solid ${theme.colors.grey60}`,
        borderRadius: `2px`,
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        position: 'relative',
        transition: 'border-color 180ms ease',
    }),

    ifProp('isHighlighted', ({ theme }) => ({
        borderColor: theme.colors.thunderBlack,
    })),
);

export const TextAreaPlaceholder = styled(Placeholder)<{ isHighlighted?: boolean }>(
    ({ theme }) => ({
        top: theme.space[3],
    }),

    ifProp('isHighlighted', () => ({
        transform: 'translateY(-150%) scale(.88)',
    })),
);
